import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import Link from 'next/link';
import Button from '../../Button';
import PromotionInfo from './PromotionInfo';
import PromotionHeader from './PromotionHeader';
import AlertModal from '../../AlertModal';

const PersonalAdsLimitPayment = ({
  onCloseHandler,
  id,
  message,
  freeAdsBalance,
  additionalMessage,
  showAlert,
  toggleAlert,
  handleSubmit
}) => {
  const { t } = useTranslation();

  return (
    <div className="promotion">
      <AlertModal
        title={t('pages.current_item.alert_modal.remove_ad')}
        isOpen={showAlert}
        message={t('pages.current_item.alert_modal.remove_ad_desc')}
        closeHandler={toggleAlert}
        acceptButtonTitle={t('pages.current_item.buttons.confirm')}
        onAcceptHandlerClick={handleSubmit}
        declineButtonTitle={t('common.cancel')}
        onDeclineHandlerClick={toggleAlert}
      />
      <PromotionHeader
        onCloseHandler={onCloseHandler}
        title={t('pages.current_item.promotion.ads_payment')}
      />
      <div className="promotion__payment-method">
        <div className="promotion__payment-method-note">
          <Trans
            i18nKey="pages.current_item.promotion.note"
            values={{
              id
            }}
          >
            <Link href={`/items/${id}`}>{id}</Link>
          </Trans>
        </div>
        <PromotionInfo
          message={message}
          additionalClasses={'promotion__info--payment'}
        />
        <PromotionInfo
          message={additionalMessage}
          additionalClasses={'promotion__info--payment'}
        />
      </div>
      <div className="promotion__proof">
        <div className="promotion__proof-container">
          <span className="promotion__proof-title">
            {t('pages.current_item.promotion.free_ads_balance', {
              freeAdsBalance
            })}
          </span>
          <PromotionInfo
            message={t('pages.current_item.promotion.free_ads_balance_info')}
            additionalClasses={'promotion__info--proof'}
          />
          <Button
            text={t('pages.current_item.buttons.pay')}
            additionalClass="bottom-phone"
            onClickHandler={toggleAlert}
          />
        </div>
      </div>
    </div>
  );
};

PersonalAdsLimitPayment.propTypes = {
  onCloseHandler: PropTypes.func,
  handleSubmit: PropTypes.func,
  toggleAlert: PropTypes.func,
  id: PropTypes.string,
  message: PropTypes.string,
  showAlert: PropTypes.bool,
  freeAdsBalance: PropTypes.string,
  additionalMessage: PropTypes.string
};
export default PersonalAdsLimitPayment;
