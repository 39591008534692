import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import BinaModal from '../ReactModal';

const AlertModal = memo(
  ({
    isOpen,
    closeHandler,
    title,
    message,
    buttonColor = 'blue',
    acceptButtonTitle,
    declineButtonTitle,
    onDeclineHandlerClick,
    onAcceptHandlerClick
  }) => {
    return (
      <BinaModal
        isOpen={isOpen}
        onRequestClose={closeHandler}
        modalId="alert-message"
        overlayClassName="modal__overlay--alert"
        contentClassName="modal__content--alert"
      >
        <div className="alert-modal">
          <div className="alert-modal-content">
            <div className="alert-modal-content__title">{title}</div>
            <div className="alert-modal-content__message">{message}</div>
          </div>
          <div className="alert-modal-btns">
            {declineButtonTitle && (
              <div
                role="button"
                tabIndex={0}
                onClick={onDeclineHandlerClick}
                onKeyPress={onDeclineHandlerClick}
                className={cn(
                  'alert-modal-btns__btn',
                  'alert-modal-btns__btn--decline'
                )}
              >
                {declineButtonTitle}
              </div>
            )}
            <div
              role="button"
              tabIndex={-1}
              onKeyPress={onAcceptHandlerClick}
              onClick={onAcceptHandlerClick}
              className={cn(
                'alert-modal-btns__btn',
                `alert-modal-btns__btn--${buttonColor}`
              )}
            >
              {acceptButtonTitle}
            </div>
          </div>
        </div>
      </BinaModal>
    );
  }
);

AlertModal.displayName = `AlertModal`;

AlertModal.propTypes = {
  isOpen: PropTypes.bool,
  closeHandler: PropTypes.func,
  onDeclineHandlerClick: PropTypes.func,
  onAcceptHandlerClick: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  declineButtonTitle: PropTypes.string,
  acceptButtonTitle: PropTypes.string,
  buttonColor: PropTypes.string,
  children: PropTypes.node
};

export default AlertModal;
