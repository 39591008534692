import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const RadioButton = ({
  data,
  onChangeHandler,
  currentValue,
  name,
  cypress,
  additionalClasses
}) => {
  return (
    <label
      className={cn('search-radio-container', additionalClasses || '')}
      data-cy="radio-container"
    >
      <input
        type="radio"
        name={name}
        className="search-radio input-hidden"
        value={data.id}
        checked={currentValue === data.id}
        onChange={onChangeHandler}
        data-cy={`${cypress}-${data.id}`}
      />
      <div className="search-radio__text" data-cy={cypress}>
        {data.name}
      </div>
      <span className="search-radio__mark" />
    </label>
  );
};

RadioButton.propTypes = {
  data: PropTypes.object,
  onChangeHandler: PropTypes.func,
  currentValue: PropTypes.string,
  name: PropTypes.string,
  cypress: PropTypes.string,
  additionalClasses: PropTypes.string
};

export default RadioButton;
