import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { togglePromotionModalView } from '../actions';

const useTogglePromotionModalView = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const splitPath = router.asPath.split('#');
  const mainPartOfLocation = splitPath[0];
  const restPartOfLocation = splitPath[1];
  const isPremiumView = restPartOfLocation === 'service_feature';
  const isRestoreView = restPartOfLocation === 'restore';
  const isPaymentMethodView = restPartOfLocation === 'limit_payment';
  const isPersonalAdsLimitView = restPartOfLocation === 'personal_ads';

  const onCloseHandler = () => {
    dispatch(togglePromotionModalView(false));

    router.push(`${mainPartOfLocation}`, undefined, {
      shallow: true
    });
  };

  const openCorrectView = route => {
    dispatch(togglePromotionModalView(true));

    router.push(`${mainPartOfLocation}#${route}`, undefined, {
      shallow: true
    });
  };

  return {
    onCloseHandler,
    openCorrectView,
    splitPath,
    isPremiumView,
    isPaymentMethodView,
    isRestoreView,
    isPersonalAdsLimitView
  };
};

export default useTogglePromotionModalView;
